var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Импорт себестоимости",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('p', {
    staticClass: "modal-text"
  }, [_vm._v(" Загрузите XLS или CSV файл с себестоимостью товаров "), _c('br'), _c('span', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" Для корректного импорта себестоимости "), _c('br'), _vm._v(" подготовьте файл в нужном формате ")]), _c('br'), _c('a', {
    staticClass: "link",
    attrs: {
      "href": "Import_Example_SalesFinder.xlsx",
      "download": ""
    }
  }, [_vm._v(" скачать пример файла ")])]), _c('div', {
    on: {
      "dragover": function ($event) {
        $event.preventDefault();
      },
      "drop": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('label', {
    staticClass: "import-block",
    on: {
      "drop": _vm.dragFile
    }
  }, [_c('input', {
    staticClass: "import-block__input",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _c('img', {
    staticClass: "import-block__image",
    attrs: {
      "src": _vm.Icon,
      "alt": "Import icon"
    }
  }), !_vm.file ? _c('span', {
    staticClass: "import-block__left"
  }, [_c('span', {
    staticClass: "import-block__title"
  }, [_vm._v("Выберите файл")]), _c('span', {
    staticClass: "import-block__text"
  }, [_vm._v("или перетащите сюда для загрузки")])]) : _c('span', {
    staticClass: "import-block__left"
  }, [_c('span', {
    staticClass: "import-block__title"
  }, [_vm._v(_vm._s(_vm.file[0].name))])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };