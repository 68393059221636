var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "Products",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "c_id",
      "selectable": _vm.selectable,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable
    },
    on: {
      "selectAction": function ($event) {
        return _vm.$emit('selectAction', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "unmapped",
      fn: function ({
        item
      }) {
        return [_c('div', [_vm.editId !== item.id ? _c('div', {
          staticClass: "cost"
        }, [_vm.editId !== item.id ? _c('span', {
          staticClass: "cost__text"
        }, [item.unmapped === -1 || item.unmapped === null ? _c('span', {
          staticClass: "gray"
        }, [_vm._v(" — ")]) : _c('span', [_vm._v(_vm._s(item.unmapped))])]) : _vm._e(), _c('button', {
          staticClass: "edit-btn",
          on: {
            "click": () => _vm.changeCost(item)
          }
        }, [_c('img', {
          staticClass: "edit-icon",
          attrs: {
            "src": require("../../assets/images/icons/edit.svg"),
            "alt": "Edit"
          }
        })])]) : _c('div', {
          staticClass: "cost"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.currentEdit,
            expression: "currentEdit"
          }],
          staticClass: "bold",
          attrs: {
            "type": "text",
            "autofocus": ""
          },
          domProps: {
            "value": _vm.currentEdit
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) return;
              _vm.currentEdit = $event.target.value;
            }
          }
        }), _c('button', {
          staticClass: "ok-btn",
          on: {
            "click": () => _vm.loadCost(item)
          }
        }, [_c('img', {
          staticClass: "ok-icon",
          attrs: {
            "src": require("../../assets/images/icons/ok.svg"),
            "alt": "Ok"
          }
        })]), _c('button', {
          staticClass: "cancel-btn",
          on: {
            "click": _vm.cancelChanging
          }
        }, [_c('img', {
          staticClass: "cancel-icon",
          attrs: {
            "src": require("../../assets/images/icons/cancel.svg"),
            "alt": "Cancel"
          }
        })])])])];
      }
    }])
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };