var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_vm.hasOzon && _vm.hasWb ? _c('app-select', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_marketplace_items,
            "fullwidth": ""
          },
          model: {
            value: _vm.marketplace,
            callback: function ($$v) {
              _vm.marketplace = $$v;
            },
            expression: "marketplace"
          }
        }) : _vm._e(), _c('app-select-multi', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_tokens_items
          },
          model: {
            value: _vm.selectedTokens,
            callback: function ($$v) {
              _vm.selectedTokens = $$v;
            },
            expression: "selectedTokens"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 152027374)
  }, [_vm._v(" Товары ")]), _c('div', {
    staticClass: "mt32"
  }, [_c('div', {
    staticClass: "table-filters"
  }, [_c('button', {
    staticClass: "btn-outline btn-ratings table-filters__import",
    on: {
      "click": _vm.onImportCostButton
    }
  }, [_vm._v(" Импорт себестоимости ")]), _c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  }), _vm.without_cost_price_total > 0 ? _c('div', {
    staticClass: "highlights__warning"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/highlights/warning.svg"),
      "alt": "Warning"
    }
  }), _c('p', {
    staticClass: "highlights__warning__text"
  }, [_vm._v(" Не указана себестоимость у " + _vm._s(_vm.without_cost_price_total) + " товаров ")])]) : _vm._e()], 1), _vm.reportSettings ? _c('products-table', {
    key: _vm.tableKey,
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "paginatable": true,
      "configurable": true,
      "withoutCostLoader": _vm.loadProductsWithoutCost,
      "file-name": _vm.fileName
    }
  }) : _vm._e()], 1), _c('modal-import-cost', {
    ref: "ModalImportCost",
    on: {
      "complete": _vm.onImported
    }
  }), _c('modal-import-cost-result', {
    ref: "ModalImportCostResult",
    attrs: {
      "result": {
        updated: this.updated,
        not_found_sku: this.not_found_sku
      }
    }
  })], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };