import "core-js/modules/esnext.iterator.map.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import ProductsTable from "@/components/Connections/ProductsTable";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import ModalImportCost from "@/components/modals/ModalImportCost.vue";
import ModalImportCostResult from "@/components/modals/ModalImportCostResult.vue";
import MyShopsMixin from "@/mixins/MyShops";
import Products from "@/mixins/products";
import HasTokens from "@/components/HasTokens";
export default {
  mixins: [MyShopsMixin, Products],
  meta: {
    title: "Товары"
  },
  data() {
    return {
      refreshKey: 0,
      updated: 0,
      not_found_sku: 0
    };
  },
  methods: {
    onImportCostButton() {
      this.$refs.ModalImportCost.open({});
    },
    onImported(result) {
      this.updated = result.data.updated;
      this.not_found_sku = result.data.not_found_sku;
      this.$refs.ModalImportCostResult.open({});
      this.loadProductsWithoutCost();
      this.refreshKey++;
    }
  },
  computed: {
    fileName() {
      return `${this.marketplace.title} Товары`;
    },
    tableKey() {
      return this.refreshKey;
    },
    reportSettings() {
      return {
        tokens: this.selectedTokens.map(item => item.id)
      };
    }
  },
  components: {
    MainTitle,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    ProductsTable,
    ModalImportCost,
    ModalImportCostResult,
    HasTokens,
    DataLoading,
    DataTableActions
  }
};